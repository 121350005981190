import React from 'react';
import Styles from './Splash.scss';
import logo from '../../../images/logo.svg';

const Splash = () => {
  return (
    <div className={Styles.splashContainer}>
      <img className={Styles.logo} src={logo} alt="Firstborn Logo" />
    </div>
  );
};

export default Splash;
