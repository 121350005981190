import React from 'react';
import { Reveal } from 'react-text-reveal';
import Styles from './ReadyScreen.scss';
import { css } from '../../utils';

class ReadyScreen extends React.Component {
  constructor() {
    super();
    this.state = { canPlay: false };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ canPlay: true });
    });
  }

  render() {
    const { playerIndex, onClick } = this.props;
    const { canPlay } = this.state;

    const boxPositionClass =
      playerIndex === 1
        ? 'left'
        : playerIndex === 2
        ? 'bottom'
        : playerIndex === 3
        ? 'right'
        : playerIndex === 4
        ? 'top'
        : 'default';

    return (
      <div className={Styles.readyScreenContainer}>
        <Reveal
          className={Styles.titleContainer}
          canPlay={canPlay}
          ease="cubic-bezier(0,0.4,0.4,1)"
        >
          <h1 className={Styles.title}>Ready</h1>
          <h1 className={Styles.title}>Player</h1>
          <h1 className={css(Styles.title, Styles.playerIndex)}>{`0${playerIndex}`}</h1>
        </Reveal>

        <div className={css(Styles.playerBoxPositionContainer, Styles[boxPositionClass])} />

        <div className={Styles.directionsContainer}>
          <ul className={Styles.directionList}>
            <li className={Styles.direction}>
              <span className={Styles.directionNumber}>1.</span>
              <div className={Styles.directionCopy}>Stand on your marker.</div>
            </li>
            <li className={Styles.direction}>
              <span className={Styles.directionNumber}>2.</span>
              <div className={Styles.directionCopy}>
                Defend your side of the screen
                <br />
              </div>
            </li>
          </ul>

          <button type="button" className={Styles.gotItButton} onClick={onClick}>
            Got It
          </button>
        </div>
      </div>
    );
  }
}

export default ReadyScreen;
