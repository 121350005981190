import React from 'react';
import Styles from './Confirm.scss';
import { css } from '../../utils';

const Confirm = ({ name, canStartGame, onStartGame }) => {
  return (
    <div className={Styles.confirmContainer}>
      <h1 className={Styles.title}>Ok,</h1>
      <h1 className={Styles.title}>{`${name || 'Bud'}!`}</h1>
      <div className={Styles.description}>When all players have joined, tap the button.</div>
      {canStartGame && (
        <button type="button" className={Styles.startGameButton} onClick={onStartGame}>
          Start Game!
        </button>
      )}
      {!canStartGame && (
        <div className={Styles.waitingMessage}>Waiting for player 1 to start the game.</div>
      )}
    </div>
  );
};

export default Confirm;
