import React from 'react';
import Styles from './Controller.scss';
import { css } from '../../utils';

class Controller extends React.Component {
  onTouchMove = e => {
    const { onSendPosition } = this.props;
    if (!onSendPosition) {
      return;
    }

    if (e.touches && e.touches[0]) {
      const newPositionX = 1 - (window.innerWidth - e.touches[0].clientX) / window.innerWidth;
      onSendPosition(newPositionX);
    }
  };

  onMouseMove = e => {
    const { onSendPosition } = this.props;
    if (!onSendPosition) {
      return;
    }
    const windowWidth = window.innerWidth * 0.9; // don't want to have to touch the ends of the phone
    const newPositionX = 1 - (window.innerWidth - e.clientX) / window.innerWidth;
    onSendPosition(newPositionX);
  };

  getControllerPadTransform = () => {
    const { player } = this.props;
    if (!player) {
      return 0.5;
    }

    const { positionX } = player;
    return `translate3d(${positionX * 400}%, 0, 0)`;
  };

  render() {
    const padTransform = this.getControllerPadTransform();

    return (
      <div
        className={Styles.controllerContainer}
        onMouseMove={this.onMouseMove}
        onTouchMove={this.onTouchMove}
      >
        <div className={Styles.controller}>
          <div className={Styles.controllerPad} style={{ transform: padTransform }} />
          <div className={Styles.controllerMiddleLine} />
        </div>
      </div>
    );
  }
}

export default Controller;
