import React from 'react';
import { Reveal, CharacterReveal } from 'react-text-reveal';
import Styles from './SignUp.scss';

class Signup extends React.Component {
  constructor() {
    super();
    this.state = { inputValue: '', error: null, canPlay: false };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ canPlay: true });
    }, 200);
  }

  onInputChange = e => {
    this.setState({ inputValue: e.target.value });
  };

  onInputFocus = () => {
    this.placeholder.style.display = 'none';
  };

  onInputBlur = () => {
    const { inputValue } = this.state;
    if (inputValue.length === 0) {
      this.placeholder.style.display = 'flex';
    }
  };

  onSubmit = () => {
    const { onNameSubmit } = this.props;
    const { inputValue } = this.state;
    if (inputValue.length > 0) {
      onNameSubmit(inputValue);
    } else {
      this.setState({ error: 'Invalid name' });
    }
  };

  render() {
    const { inputValue, error, canPlay } = this.state;

    return (
      <div className={Styles.signUpContainer}>
        <div className={Styles.titleContainer}>
          {/* <CharacterReveal
            animateOpacity
            canPlay={canPlay}
            characterOffsetDelay={25} // ms
            characterWordSpacing="2vw"
            copy={['Oh hey,', 'ready to', 'shine?']}
            direction="bottom"
            duration={500} // ms
            ease="cubic-bezier(0,0.4,0.4,1)"
            offset="25px"
            multilineMasking
            multilineOffsetDelay={200}
          /> */}

          <Reveal canPlay={canPlay} ease="cubic-bezier(0,0.4,0.4,1)">
            <h1 className={Styles.title}>Oh hey,</h1>
            <h1 className={Styles.title}>ready to</h1>
            <h1 className={Styles.title}>shine?</h1>
          </Reveal>
        </div>
        <div className={Styles.bottomContent}>
          <div className={Styles.inputContainer}>
            <div
              className={Styles.inputPlaceholder}
              ref={r => {
                this.placeholder = r;
              }}
            >
              Enter Your Name*
            </div>
            <input
              className={Styles.nameInput}
              type="text"
              value={inputValue}
              ref={r => {
                this.nameInputRef = r;
              }}
              onChange={this.onInputChange}
              onFocus={this.onInputFocus}
              onBlur={this.onInputBlur}
            />
            {error && <div className={Styles.errorMessage}>{error}</div>}
          </div>
          <button type="button" className={Styles.submitButton} onClick={this.onSubmit}>
            Go!
          </button>
        </div>
      </div>
    );
  }
}

export default Signup;
