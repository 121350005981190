import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import io from 'socket.io-client';
import Splash from './components/Splash/Splash';
import Confirm from './components/Confirm/Confirm';
import SignUp from './components/SignUp/SignUp';
import ReadyScreen from './components/ReadyScreen/ReadyScreen';
import Controller from './components/Controller/Controller';

const GAME_STATES = {
  join: 0,
  playing: 1,
  gameover: 2
};

class App extends Component {
  constructor() {
    super();
    this.state = {
      confirmRules: false,
      name: '',
      joining: false,
      loading: true,
      playerSocketId: '',
      joinedServer: false,
      joinedGame: false,
      gameState: null,
      error: null
    };

    this.socket = io('http://localhost:3000');

    this.timeoutId = null;
  }

  componentDidMount() {
    this.bindSocketListeners();
    this.timeoutId = setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  bindSocketListeners = () => {
    this.socket.on('joined server', player => {
      console.log('joined server');
      this.setState({ playerSocketId: player.id, joinedServer: true });
    });

    this.socket.on('connect_error', () => {
      console.log('whoops');
      this.setState({ error: 'Trouble connecting' });
    });

    this.socket.on('disconnect', () => {
      this.setState({ joinedServer: false, joinedGame: false });
      console.log('disconnected');
    });

    this.socket.on('failed to join', errorMessage => {
      // show alert with error message
      this.setState({ error: errorMessage, joinedServer: false, joinedGame: false });
    });

    this.socket.on('game state', gameState => {
      this.setState({ gameState });
    });

    this.socket.on('joined game', player => {
      // start game loop
      const { id } = player;

      this.setState({
        joining: false,
        playerSocketId: id,
        joinedGame: true
      });
    });
  };

  onJoinBtnClick = () => {
    const { joinedServer } = this.state;

    if (!joinedServer) {
      const name = this.nameInput.value || 'Guest';
      this.socket.emit('join server', name);
    }
  };

  getPlayerState = () => {
    const { gameState, playerSocketId } = this.state;
    if (!gameState) {
      return null;
    }
    const playerKey = Object.keys(gameState.players).find(playerId => playerId === playerSocketId);
    if (!playerKey) {
      return null;
    }

    return gameState.players[playerKey];
  };

  onNameSubmit = name => {
    this.setState({ name, joining: true });
    this.socket.emit('join server', name);
  };

  onStartGame = () => {
    this.socket.emit('start game');
  };

  onReadyConfirm = () => {
    this.setState({ confirmRules: true });
  };

  onSendPosition = newPositionX => {
    this.socket.emit('player position', newPositionX);
  };

  render() {
    const { joinedGame, gameState, name, loading, confirmRules } = this.state;

    const playerState = this.getPlayerState();
    const inJoin = joinedGame && gameState && gameState.state === GAME_STATES.join;
    const inGame = joinedGame && gameState && gameState.state === GAME_STATES.playing;
    const gameOver = joinedGame && gameState && gameState.state === GAME_STATES.gameover;
    const playerIndex = (playerState && playerState.playerIndex) || 0;
    const canStartGame = playerIndex === 1 && Object.keys(gameState.players).length > 1;

    return (
      <div className="wrapper">
        {inGame && <Controller onSendPosition={this.onSendPosition} player={playerState} />}
        {!inGame && joinedGame && inJoin && confirmRules && (
          <Confirm name={name} canStartGame={canStartGame} onStartGame={this.onStartGame} />
        )}

        {!inGame && joinedGame && inJoin && (
          <ReadyScreen playerIndex={playerIndex} onClick={this.onReadyConfirm} />
        )}

        {/* {<ReadyScreen playerIndex={playerIndex} onClick={this.onReadyConfirm} />} */}
        {!inGame && !joinedGame && <SignUp onNameSubmit={this.onNameSubmit} />}
        {/* {loading && <Splash />} */}
      </div>
    );
  }
}

ReactDOM.render(<App />, document.getElementById('app'));
